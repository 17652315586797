:root {
  --primary-color:#082D33 ;
  --secondary-color: #AADAEE ;
  --accent1-color: #A3B18A ;
  --accent2-color: #344E41;
  
  --heading-font: 'oswald';
  --paragraph-font: 'oswald';
  
  --nav-background-color:#588157;
  --nav-link-color:#344E41;
  --nav-hover-link-color: #DAD7CD;
  --nav-hover-background-color:#A3B18A;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.grid {
  width: 100%;
}

body {
  font-family: Arial, sans-serif;
  margin: 20px;
}

h1 {
  text-align: center;
}

form {
  /* max-width: 500px; */
  margin: 0 auto;
  padding: 20px;
  background-color: #f2f2f2;
  border-radius: 5px;
}

label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

input[type="text"],
input[type="number"],
textarea {
  width: 100%;
  padding: 8px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

select {
  width: 100%;
  padding: 8px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  appearance: none;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath d='M7 10l5 5 5-5z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 8px center;
  background-size: 24px;
  padding-right: 30px;
}

input[type="radio"],
input[type="checkbox"] {
  margin-right: 5px;
}

input[type="submit"] {
  /* background-color: #2499c7; */
  background-color: var(--primary-color);

  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
}

input[type="submit"]:hover {
  background-color: #0056b3;
}

.grid {
  contain: content;
}


/*

LOGIN GOOGLE


*/

.container {
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
}

.login-box {
  background-color: var(--primary-color);
  padding: 40px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin: auto;
  color: var(--primary-color);
  margin-bottom: 20px;
}

h1 {
  text-align: center;
  color: #fff;
  margin-bottom: 20px;
}
.signup-link span {
  color: #fff;
}

form {
  margin-bottom: 20px;
}

.form-group {
  margin-bottom: 15px;
}

label {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
}

input[type="email"],
input[type="password"] {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

button {
  display: block;
  width: 100%;
  padding: 12px;
  background-color: var(--primary-color);
  color: #fff;
  border: none;
  border-radius: 4px;
  font-weight: bold;
  cursor: pointer;
}

button:hover {
  background-color: #616161;
  color: #fff;
}

.signup-link {
  text-align: center;
}

.signup-link a {
  color: #333;
  text-decoration: underline;
}

.signup-link a:hover {
  color: #ff6f00;
}


.google-login {
  text-align: center;
  margin-top: 20px;
}

.google-btn {
  display: inline-flex;
  align-items: center;
  background-color: #fff;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 10px 16px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.google-btn:hover {
  background-color: #c23321;
}

.google-btn {
  font-family: 'Roboto', sans-serif;
  color: #878787;
  height: 1.5rem;
}

.logo-google {
  height: 30px;
  width: 30px;
  vertical-align: middle;
  margin-right: 8px;
}

.google-btn a {
  display: block;
  padding: 0;
}

.google-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: 4px;
  padding: 20px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.google-btn:hover {
  background-color: #616161;
  color: #fff;
}

.login-btn {
  color: #fff;
  background-color: var(--primary-color);
}

form button,
form div.google-btn {
  margin: 3px;
}

.signup-link a {
  color: #afafaf;
}

.link-google {
  text-decoration: none;
}

/* Varios */

.bg-primary {
  background-color: var(--primary-color);
}

.text-primary {
  color: var(--primary-color)!important;
}

footer {
  color: #fff;
}



.card-servicios img {
  border-radius: 15px 15px 0 0;
}

.social-icons {
  display: flex;
  justify-content: space-evenly;
}

/*
Landing page
*/

/* Estilos para la landing page */
.landing-page {
  font-family: Arial, sans-serif;
}

/* Estilos para el encabezado */
.header {
  background-color: #ffffff; /* Color de fondo blanco */
  padding: 20px;
}

/* Estilos para la sección de bienvenida */
.welcome-section {
  background-color: #f0f0f0; /* Color de fondo gris claro */
  padding: 50px 0;
  text-align: center;
}

/* Estilos para la sección de servicios */
.services-section {
  padding: 50px 0;
  text-align: center;
}

/* Estilos para la sección de testimonios */
.testimonials-section {
  background-color: #ffffff; /* Color de fondo blanco */
  padding: 50px 0;
  text-align: center;
}

/* Estilos para la sección de contacto */
.contact-section {
  padding: 50px 0;
  text-align: center;
}

.btn-custom {
  display: block;
  width: 100%;
  padding: 12px;
  background-color: var(--primary-color);
  color: #fff;
  border: none;
  border-radius: 4px;
  font-weight: bold;
  cursor: pointer;
}


/* Estilos para dispositivos pequeños */
@media screen and (max-width: 600px) {
  .w3-sidebar {
      width: 0;
  }
  .w3-sidebar.w3-collapse {
      display: none;
  }
}

.title-solicitud {
  color: #000;
  text-align: center;
  margin-top: 0;
  margin-bottom: 20px;
  font-weight: bold;
}

.fa-bars {
  color: var(--primary-color);
}

#portfolio {
  background-color: #f2f2f2;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.w3-main {
  background-color: #f2f2f2;
}

.text-center {
  text-align: center;
}

.stars-container {
  display: flex;
  margin: 0 auto;
}

.btn-calificar {
  background-color: #000;
}

.footer-servicio {
  background-color: #fff;
}

.bg-primary {
  background-color: var(--primary-color);
}

.bg-secondary {
  background-color: var(--secondary-color);
}

.bg-primary-w-opacity {
  background-color: var(--primary-color);
  opacity: 80%;
}

.grid {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.grid .card-servicios {
  background-color: #fff;
}

.container-servicios > * {
  width: 33%;
}

.radius-15 {
  border-radius: 15px;
}

.radius-20-inf {
  border-radius: 0 0 20px 20px;
}

.centered-25 {
  width: 25%;
  min-width: 300px;
  padding: 5px;
  margin: auto;
}

.centered-75 {
  width: 75%;
  padding: 5px;
  margin: auto;
}

.w-100 {
  width: 100%;
}

.height-300 {
  height: 300px;
}

.hero picture, .hero picture img {
  display: block;
  max-height: 300px;
}

.hero picture img {
  position: absolute;
  object-fit: cover;
}

.hero {
  position: relative;
}

.hero-p {
  position: relative;
  bottom: -60px;
  padding: 10px;
  font-weight: 700;
}

.hero-title {
  position: relative;
  top:15%;
  width: 75%;
  min-width: 280px;
  margin: 0 auto;
  border-radius: 25px;
  padding: 5px;
}

.hero-title h1 {
  font-size: 30px;
}

.text-center {
  text-align: center;
}


.main-article picture img {
  object-fit: cover;
  width: 90%;
  height: 250px;
  border-radius: 25px;
  display: block;
  margin: 0 auto;
}

.main-article-title {
  margin-top: 10px;
  background-color: var(--secondary-color);
}

.text-secondary {
  color: var(--secondary-color);
}

.p-2 {
  padding: 20px;
}

.main-article-p {
  border-radius: 20px;
  margin: 10px;
}

.main-article-section {
  margin-top: 10px;
}

.main-article-section a {
  margin: 10px auto;
}

.landing-servicios h2 {
  text-align: center;
  color: var(--primary-color);
  font-weight: 700;
}

.card-landing {
  position: relative;
}

.card-landing p {
  position: absolute;
  bottom: 10px;
  margin: auto;
  text-align: center;
  width: 90%;
  background-color: var(--primary-color);
  color: var(--secondary-color);
  border-radius: 15px;
  right: 5%;
  left: 5%;
}

.card-landing {
  width: 30%;
  min-height: 250px;
  min-width: 250px;
  margin: 5px;
  object-fit: cover;
  border-radius: 25px;
}

.picture-landing img {
  position: absolute;
  object-fit: cover;
  width: 100%;
  height: 100%;
  border-radius: 25px;
}

.card-servicios {
  margin: 10px;
  padding: 0;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.card-servicios > div { 
  padding: 10px;
  height: -webkit-fill-available;
}

.footer-servicio {
  height: auto!important;
}

.container-servicios, .opciones-servicios {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  contain: content;
}

.opciones-servicios > * {
  width: 30%;
}

.form-inputs {
  max-width: 500px;
  margin: 0 auto;
}

.active-menu {
  background-color: var(--primary-color);
  color: var(--secondary-color)!important;
}

@media screen and (min-width: 601px) {
  .w3-sidebar {
      width: 300px;
  }
  .w3-sidebar.w3-collapse {
      display: block;
  }
  .card-servicios {
    width: 30%;
    margin: 10px;
    padding: 0;
    border-radius: 15px 15px 0 0;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  .main-article {
    display: flex;
  }

  .main-article picture {
    width: 50%;
    padding: 20px;
  }

  .main-article > div {
    width: 50%;
  }

  .hero-title h1 {
    font-size: 36px;
  }

  .card-servicios {
    flex: 0 1 calc(33% - 20px);
    margin-bottom: 20px;
  }

}

.image-container {
  height: 300px; /* Alto fijo del contenedor */
  overflow: hidden; /* Oculta cualquier desbordamiento de la imagen */
}

.responsive-image {
  width: 100%;
  height: 100%;
  object-fit: cover; /* La imagen cubre todo el contenedor manteniendo su proporción */
  object-position: center; /* Centra la imagen dentro del contenedor */
}

.bg-cta {
  background-color: #9B2C2C;
  color: #fff!important;
}